import React from "react";
import "../input.css";

import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import Background from "../assets/img/splash.jpg";

export default function Landing() {
  return (
    <>
      <Navbar />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "75vh",
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: `url(${Background})`,
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-65 bg-orange-100"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <img
                    alt="..."
                    className="max-w-full ml-auto mr-auto mb-10"
                    src={require("../assets/img/bblogo2.png")}
                  />
                  <h1 className="text-cyan-800 font-semibold text-4xl">
                    Jessica Whitson, ICD
                  </h1>
                  <p className="mt-4 text-lg text-lime-950">
                    BIRTH AND POSTPARTUM DOULA
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute -bottom-1 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-amber-50 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-amber-50 -mt-24">
          <div className="container mx-auto px-4" id="services">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-100 h-100">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/birthart.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-amber-50">
                      Birth Doula Services
                    </h6>
                    <p className="mt-2 mb-4 text-amber-50 text-center">
                      I will provide you with physical, emotional, and
                      informational support throughout your pregnancy, birth,
                      and postpartum period. Your birth should be a positive and
                      empowering experience. My goal is to help you feel
                      supported and truly cared for during your pregnancy and
                      birth.
                    </p>
                    <a
                      className="bg-orange-300 text-gray-800 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      href="#birth"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-100 h-100">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/postparart.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-amber-50">
                      Postpartum Doula Services
                    </h6>
                    <p className="mt-2 mb-4 text-amber-50 text-center">
                      I will support you and your family through this
                      transformational time. I will provide tangible and
                      emotional support as you get to know your baby, recover
                      from birth, and adjust to this new chapter of life. My
                      services look different for each family I assist and are
                      tailored to fit your unique and specific needs.
                    </p>
                    <a
                      className="bg-orange-300 text-gray-800 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      href="#postb"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-gray-800 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-100 h-100">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/postaart.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-amber-50">
                      Post Adoption
                    </h6>
                    <p className="mt-2 mb-4 text-amber-50 text-center">
                      Adoption is a beautiful way to grow your family. You have
                      planned and prepared for so long while you’ve waited to
                      meet your baby. The first moments at home should be spent
                      bonding with your baby while you adjust to your new
                      chapter of life. I will provide emotional and
                      informational support as you step into your new role as a
                      parent. My services are tailored to fit your unique and
                      specific needs and are flexible, as needs tend to change
                      depending on the day and depending on your family.
                    </p>
                    <a
                      className="bg-orange-300 text-gray-800 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      href="#posta"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-800">
                  "Birth is not only about making babies. Birth is about making
                  mothers - strong, competent, capable mothers who trust
                  themselves and know their inner strength." - Barbara Katz
                  Rothman.
                </p>
              </div>
            </div>
            <div className="align-center text-center">
              <img
                alt="..."
                src={require("../assets/img/bbb7.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
              <img
                alt="..."
                src={require("../assets/img/IMG_2924.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
              <img
                alt="..."
                src={require("../assets/img/bblinka-5.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
              <img
                alt="..."
                src={require("../assets/img/bbb8.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>
        <section className="relative py-20 bg-gray-800">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "81px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4 lg:pt-10 lg:pb-50" id="birth">
            <div className="flex flex-wrap text-center justify-center">
              <div className="align-center text-center">
                <img
                  alt="..."
                  src={require("../assets/img/bbb3.jpg")}
                  className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-amber-50">
                  Birth Doula Services
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-amber-50">
                  I will provide you with physical, emotional, and informational
                  support throughout pregnancy, birth, and postpartum period.
                  Your birth should be a positive and empowering experience. My
                  goal is to help you feel supported and truly cared for during
                  your pregnancy and birth.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center" id="postp">
              <div className="w-full lg:w-3/12 px-4 text-amber-50">
                <div className="text-gray-900 p-3 w-24 h-24 shadow-lg rounded-full inline-flex items-center justify-center">
                  <img
                    alt="..."
                    className="max-w-full"
                    src={require("../assets/img/herb1.png")}
                  />
                </div>
                <h6 className="text-xl mt-5 font-semibold text-amber-50">
                  Prenatal time
                </h6>
                <p className="mt-2 mb-4 text-amber-50 indent-3">
                  We will meet for 2-3 visits during your pregnancy. During
                  these visits, I will get to know you and your family so I
                  understand how to best support you. Everyone has a different
                  vision on what a positive birth experience is for them. We
                  will discuss birth options and your goals, and I can help you
                  put together a birth plan that meets your vision and birth
                  preferences. This time builds trust between us and helps to
                  instill confidence in expectant parents regarding their
                  ability to birth and parent their new little one.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 justify-center">
                <div className="text-gray-900 p-3 w-24 h-24 shadow-lg rounded-full inline-flex items-center justify-center">
                  <img
                    alt="..."
                    className="max-w-full"
                    src={require("../assets/img/herb2.png")}
                  />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-amber-50">
                  During your labor and birth
                </h5>
                <p className="mt-2 mb-4 text-amber-50 indent-3">
                  During your labor and birth I will provide continual support
                  to you and your partner/birth team. I will make suggestions
                  for position changes and movement, will remind you to stay
                  nourished and hydrated, and will strive to keep the atmosphere
                  calm. If you desire, I will come to your home to guide you
                  when making your decision as to when you should leave for your
                  birth place or when to call your midwife or other medical
                  provider.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 justify-center">
                <div className="text-gray-900 p-3 w-24 h-24 shadow-lg rounded-full inline-flex items-center justify-center">
                  <img
                    alt="..."
                    className="max-w-full"
                    src={require("../assets/img/herb3.png")}
                  />
                </div>
                <h5 className="text-xl mt-5 font-semibold text-amber-50">
                  When baby arrives
                </h5>
                <p className="mt-2 mb-4 text-amber-50 indent-3">
                  I will stay for the first hour or until you are nice and cozy
                  in bed with your baby. I can help with getting baby latched on
                  for their first feeding if you choose to breastfeed your baby.
                  I will get you set up with snacks or a meal and some juice to
                  re-nourish your body after your labor and birth. I can also
                  take photos of your first moments with your baby, so that you
                  have those precious memories to look back on. After you are
                  settled, I will leave so that you (and your partner) can have
                  some time alone to bond with your baby and as a new family
                  unit.
                </p>
                <p className="mt-2 mb-4 text-amber-50 indent-3">
                  Within the first week I will visit your family to review your
                  birth story and make sure you are all adjusting well to life
                  with your new baby.
                </p>
              </div>
            </div>
            <div className="align-center text-center mt-5">
              <img
                alt="..."
                src={require("../assets/img/bbb011.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>
        <section className="relative py-20 bg-amber-50" id="postb">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "81px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-amber-50 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4 lg:pt-10 lg:pb-50">
            <div className="flex flex-wrap text-center justify-center">
              <div className="align-center text-center">
                <img
                  alt="..."
                  src={require("../assets/img/bbb6.jpg")}
                  className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-gray-800">
                  Postpartum Doula Services
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-800">
                  As your postpartum doula, I will support your family through
                  this transformational time. My goal is to see more people and
                  families thrive during, not just survive, the postpartum
                  period. As a society, the focus tends to be on pregnancy,
                  birth, and then ends when baby is born. I believe that new
                  parents, and all family members, need more support during the
                  first few months of your baby's life.
                </p>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-800">
                  I will provide tangible and emotional support as you get to
                  know your baby, recover from birth, and adjust to this new
                  chapter of life. We will talk about what your needs might be
                  ahead of time, while also remaining flexible as needs tend to
                  change often during those first couple months. I look forward
                  to talking with you more about how I can best serve your
                  family through your postpartum experience.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-2/12 px-4 text-gray-800"></div>
              <div className="w-full lg:w-4/12 px-4 justify-center">
                <h5 className="text-xl mt-5 font-semibold text-gray-800">
                  Postpartum doula services may include:
                </h5>
                <ul className="list-none mt-6">
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">Lactation support</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Bottle feeding support
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">Newborn care support</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Light housekeeping such as laundry and dishes
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Talking through and processing your birth
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Emotional support and encouragement
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Facilitate attachment parenting
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">Meal preparation</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Baby care while you rest
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Help with postpartum recovery
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-gray-800">
                          Bengkung belly binding
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-2/12 px-4 justify-center"></div>
            </div>
            <div className="align-center text-center mt-7">
              <img
                alt="..."
                src={require("../assets/img/bbb012.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>
        <section className="relative py-20 bg-gray-800" id="posta">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "81px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4 lg:pt-10 lg:pb-50">
            <div className="flex flex-wrap text-center justify-center">
              <div className="align-center text-center">
                <img
                  alt="..."
                  src={require("../assets/img/adopt1.jpg")}
                  className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
                />
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-amber-50">
                  Post Adoption
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-amber-50">
                  Adoption is a beautiful way to grow your family. You have
                  planned and prepared for so long while you’ve waited to meet
                  your baby. Those first moments home should be spent bonding as
                  a family as you adjust to this new chapter of life.
                </p>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-amber-50">
                  As your post adoption doula, I will support you in the way
                  that will best serve your family. Every family is different as
                  well as their needs. My services look different for each
                  family I assist and are tailored to fit your unique and
                  specific needs and are flexible, as needs tend to change
                  depending on the day and depending on your family. I look
                  forward to talking with you more about how I can best serve
                  your family through your post adoption experience. Post
                  adoption services may include:
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-2/12 px-4 text-amber-50"></div>
              <div className="w-full lg:w-4/12 px-4 justify-center">
                <h5 className="text-xl mt-5 font-semibold text-amber-50">
                  Post adoptive services may include:
                </h5>
                <ul className="list-none mt-6">
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-amber-50">Feeding support</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-amber-50">Baby care support</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-amber-50">
                          Light housekeeping such as laundry and dishes
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-amber-50">
                          Emotional support and encouragement
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-amber-50">Meal preparation</h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-1">
                    <div className="flex items-center">
                      <div>
                        <h4 className="text-amber-50">
                          Baby care while you rest
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-2/12 px-4 justify-center"></div>
            </div>
            <div className="align-center text-center mt-5">
              <img
                alt="..."
                src={require("../assets/img/adopt2.jpg")}
                className="w-6/12 align-middle mx-auto mb-10 rounded-lg shadow-lg"
              />
            </div>
          </div>
        </section>
        <section className="relative py-20 bg-amber-50">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "81px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-amber-50 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4" id="prices">
            <div className="flex flex-wrap">
              <div className="w-full p-4">
                <h2 className="text-4xl font-semibold text-cyan-900 items-center text-center">
                  Birth Packages
                </h2>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words border-2 border-cyan-900 bg-amber-50 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/herb1.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-cyan-900">
                      Guided Journey to Parenthood - $500
                    </h6>
                    <ul className="list-none mt-6 px-2">
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Prenatal and postpartum sessions to help prepare
                              you for a positive birth experience and the first
                              6 weeks of postpartum. All sessions can be
                              virtual, if desired. Great for someone looking for
                              support and guidance, but does not want in-person
                              birth support. Includes:
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              1-2 in-home prenatal meetings to teach your
                              partner hands-on comfort techniques and create a
                              birth plan. This can also be helpful in preparing
                              for a planned cesarean.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              1 in-home prenatal session focused on preparing
                              for newborn care and postpartum healing.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              1 in-home postpartum wellness visit. Included:
                              birth story processing and to check in and see how
                              you’re adjusting.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              1 postpartum care package filled with items to
                              assist in your postpartum healing.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Ability to message doula throughout pregnancy and
                              6 weeks postpartum to get expert answers for your
                              current questions.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Hands-on breastfeeding support at the postpartum
                              visit.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Benkung belly binding for postpartum comfort
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words border-2 border-cyan-900 bg-amber-50 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/herb3.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-cyan-900">
                      Birth Doula Package - $1800
                    </h6>
                    <ul className="list-none mt-6 px-2">
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              2 In-Person Prenatal Meetings
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Personalized care focused on meeting your
                              individual needs and values.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Unlimited Phone/Text/Email support throughout
                              pregnancy and postpartum.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Curated guidance for your partner or support
                              person.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Tips and tricks throughout pregnancy and beyond.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Support creating and writing up a “Birth Plan”.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              On-call for you starting at 37 weeks through the
                              birth of your baby.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              In-person labor support through 1-2 hours after
                              your baby is born.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Use of tens unit
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              1 postpartum care package filled with items to
                              assist in your postpartum healing.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Photo documentation of your birth, if desired.
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              1-2 in person postpartum visits to check in and
                              see hour you're adjusting.
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words border-2 border-cyan-900 bg-amber-50 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/herb2.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-cyan-900">
                      Birth and Postpartum Package - $2200
                    </h6>
                    <ul className="list-none mt-6 px-2">
                      <li className="py-1">
                        <div className="flex items-center pb-2">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              The{" "}
                              <span className="font-bold italic">
                                Birth Doula Package
                              </span>{" "}
                              and 4 postpartum visits at 3 hours each which will
                              be tailored to your families needs. This may
                              include:
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Lactation/feeding support
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Talking through and processing your birth
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Emotional support and encouragement
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Help with postpartum recovery
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Bengkung belly binding
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Newborn care support
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Baby care while you rest
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2 border-b border-neutral-300">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Light housekeeping
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-1">
                        <div className="flex items-center pb-2">
                          <div>
                            <h4 className="text-neutral-900 text-left">
                              Plant based meal preparation
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="lg:pt-12 pt-6 w-full md:w-12/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words border-2 border-cyan-900 bg-amber-50 w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-24 h-24 mb-5">
                      <img
                        alt="..."
                        className="max-w-full"
                        src={require("../assets/img/herb5.png")}
                      />
                    </div>
                    <h6 className="text-xl font-semibold text-cyan-900">
                      Postpartum/Postadoption Doula services
                    </h6>
                    <h6 className="text-xl text-cyan-900">
                      $50 per hour from 7am-7pm
                    </h6>
                    <h6 className="text-xl text-cyan-900">
                      $55 per hour for overnight services from 7pm to 7am
                    </h6>
                    <div className="mt-6 px-2 text-center">
                      <ul className="mb-1">
                        <li className="text-neutral-900 text-center mb-2">
                          Lactation support
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Bottle feeding support
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Newborn care support
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Light housekeeping such as laundry and dishes.
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Talking through and processing your birth emotional
                          support and encouragement.
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Facilitate attachment parenting.
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Meal preparation.
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Baby care while you rest.
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          help with postpartum recovery.
                        </li>
                        <li className="text-neutral-900 text-center mb-2">
                          Bengkung belly binding.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-20 bg-gray-800" id="about">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "81px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-4/12 ml-auto mr-auto">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-lg mb-4"
                src={require("../assets/img/IMG_2941.jpg")}
              />
            </div>
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-50 h-50 mb-6">
                  <img
                    alt="..."
                    className="max-w-full"
                    src={require("../assets/img/herb4.png")}
                  />
                </div>
                <h3 className="text-3xl font-semibold text-amber-50">
                  Meet Jessica
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-amber-50 indent-2">
                  Hello! I'm Jessica Whitson, a certified birth and postpartum
                  doula through ICEA and BAI, I live in Tacoma, Washington and
                  support families throughout the South Puget Sound area. I have
                  supported over 500 families through pregnancy, birth, and
                  postpartum since 2006.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-amber-50 indent-2">
                  I am passionate about birth and helping families have a
                  positive and beautiful birth and postpartum experience. I
                  bring my calm presence and years of expertise to support you
                  as you start this next chapter of your life. I am vegan and
                  strive to provide nourishing care, products, and food with
                  this mindset, but will always tailor my services to fit your
                  specific needs (dietary or otherwise).
                </p>
                <p className="mt-4 text-lg leading-relaxed text-amber-50 indent-2">
                  I was first intimately introduced to birth when I was 12 years
                  old and my sister became a mother. I was with her everyday as
                  she experienced pregnancy for the first time and she allowed
                  me to be in the room with her throughout her labor and the
                  birth. It was nothing like the video they showed in health
                  class! There was a magic in the room - my sister was becoming
                  a mother and my nephew was this amazing little human coming
                  into our lives who filled our famly with overwhelming joy. My
                  sister graciously invited me to be with her during the birth
                  of my 2 nieces over the next 4 years. I was asked to cut the
                  umbilical cord of my second niece, my sister’s last baby, and
                  that memory has stayed with me over years.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-amber-50 indent-2">
                  Being able to have that experience with my nephew, and the
                  subsequent moments with my nieces, sparked my love and passion
                  for the entire birth process and supporting individuals and
                  families during this transformational time. Being present
                  during the pregnancy, labor, birth, and postpartum time of
                  three babies set the course for my life in a direction that I
                  was unaware of at the time, but am truly grateful for.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-amber-50 indent-2">
                  By the time I was pregnant with my son, I had attended enough
                  births to know where I wanted to give birth and the support
                  team I wanted with me. I didn’t know about doulas yet, but I
                  was surrounded by women in my life who continually supported
                  me throughout my labor and birth. Becoming a mother myself
                  taught me how strong I really was, and motherhood has
                  continued to teach me about love and resilience and how we are
                  given different tools as we need them. It has also taught me
                  how important community and a support system is. I wanted all
                  mothers and parents to have a positive transition into this
                  new phase of life and when my son was 3, I decided to make
                  birth work my life’s work.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-amber-50 indent-2">
                  I became a certified birth and postpartum doula in 2006 and am
                  still so incredibly thankful for this work. Every birth is
                  unique and I continue to learn and grow as a doula with each
                  birth I attend. While everyone has a different birth
                  experience, one thing rings true throughout each one; your
                  birth experience is important. You will remember your birth
                  for the rest of your life. You will remember who was there,
                  how they made you feel, and how your space felt while you were
                  laboring and giving birth. Most importantly, you will have
                  your beautiful child, the amazing result of your birth. My job
                  is to guide you through this process and help ensure that your
                  experience is positive and what you want it to be.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-20 relative block bg-amber-50" id="contact">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "81px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-amber-50 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-20 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-gray-800">
                  Let's Connect
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-800">
                  No obligation, I would love to connect in person to answer any
                  questions. You have so many options, which you may or may not
                  know about. My heart is to guide you to understand the
                  possibilities, to enable you to make the next right choice
                  along your journey to motherhood and beyond.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="relative block py-24 lg:pt-0 bg-amber-50">
          <div className="container mx-auto px-4 pb-10">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-cyan-900">
                  <form action="https://api.web3forms.com/submit" method="POST">
                    <input
                      type="hidden"
                      name="access_key"
                      value="ae382dea-b662-44ae-9e78-b2e99d84b74d"
                    />
                    <div className="flex-auto p-5 lg:p-10">
                      <h4 className="text-2xl font-semibold text-amber-50">
                        Contact me to set up a consultation
                      </h4>
                      <p className="leading-relaxed mt-1 mb-4 text-amber-50">
                        Complete this form and I will get back to you in 24
                        hours.
                      </p>
                      <div className="relative w-full mb-3 mt-8">
                        <label
                          className="block uppercase text-amber-50 text-xs font-bold mb-2"
                          htmlFor="full-name"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Full Name"
                          name="name"
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Email"
                          name="email"
                          style={{ transition: "all .15s ease" }}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-amber-50 text-xs font-bold mb-2"
                          htmlFor="message"
                        >
                          Message
                        </label>
                        <textarea
                          rows="4"
                          cols="80"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Type a message..."
                          name="message"
                        />
                      </div>
                      <div className="text-center mt-6">
                        <div className="text-center pb-3"></div>
                        <button
                          className="bg-orange-300 text-gray-800 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-4 lg:pt-20 lg:pb-50">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <h2 className="text-4xl font-semibold text-gray-800">
                  Birth and Bilinka Etsy Shop
                </h2>
                <div className="items-center flex flex-wrap mt-10">
                  <div className="w-full md:w-4/12 ml-auto mr-auto px-2">
                    <a
                      href="https://birthandbilinka.etsy.com/listing/1575652157"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="..."
                        className="max-w-full rounded-lg shadow-lg"
                        src={require("../assets/img/bbbelly.jpeg")}
                      />
                    </a>
                    <a
                      className="hover:underline"
                      href="https://birthandbilinka.etsy.com/listing/1575652157"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="mt-4 text-lg leading-relaxed text-gray-800 indent-2">
                        Natural Belly Binding
                      </p>
                    </a>
                  </div>
                  <div className="w-full md:w-4/12 ml-auto mr-auto px-2">
                    <a
                      href="https://birthandbilinka.etsy.com/listing/1449571314"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="..."
                        className="max-w-full rounded-lg hover:shadow-lg mb-4"
                        src={require("../assets/img/IMG_0989.jpeg")}
                      />
                    </a>
                    <a
                      className="hover:underline"
                      href="https://birthandbilinka.etsy.com/listing/1449571314"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="mt-4 text-lg leading-relaxed text-gray-800 indent-2">
                        Postpartum Care Package
                      </p>
                    </a>
                  </div>
                  <div className="w-full md:w-4/12 ml-auto mr-auto px-2">
                    <a
                      href="https://birthandbilinka.etsy.com/listing/1486590657"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="..."
                        className="max-w-full rounded-lg hover:shadow-lg mb-4"
                        src={require("../assets/img/herbalbb.jpeg")}
                      />
                    </a>
                    <a
                      className="hover:underline"
                      href="https://birthandbilinka.etsy.com/listing/1486590657"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="mt-4 text-lg leading-relaxed text-gray-800 indent-2">
                        Organic Herbal Nipple Butter
                      </p>
                    </a>
                  </div>
                  <div className="w-full md:w-12/12 ml-auto mr-auto my-7">
                    <a
                      className="bg-cyan-800 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      href="https://www.etsy.com/shop/birthandbilinka/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visit my Etsy Shop
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
