import React from "react";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative shadow-lg bg-amber-50 shadow-lg") +
        " flex flex-wrap items-center justify-between px-2 py-3 "
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a
            className={
              (props.transparent ? "text-gray-800" : "text-gray-800") +
              " text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            }
            href="https://birthandbilinka.com"
          >
            Birth and Bilinka
          </a>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-gray-800" : "text-gray-800") +
                " fas fa-bars"
              }
            ></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-row sm:w-full md:w-full lg:w-fit items-center bg-amber-50 lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:mr-auto px-3 py-2 lg:pl-10">
            <li className="flex items-center">
              <a style={{ transition: "all .15s ease" }} href="#services">
                Services
              </a>
            </li>
          </ul>
          <ul className="flex flex-col lg:flex-row list-none lg:mr-auto px-3 py-2 lg:pl-10">
            <li className="flex items-center">
              <a style={{ transition: "all .15s ease" }} href="#prices">
                Prices
              </a>
            </li>
          </ul>
          <ul className="flex flex-col lg:flex-row list-none lg:mr-auto px-3 py-2 lg:pl-10">
            <li className="flex items-center">
              <a style={{ transition: "all .15s ease" }} href="#about">
                About me
              </a>
            </li>
          </ul>
          <ul className="flex flex-col lg:flex-row list-none lg:mr-auto px-3 py-2 lg:pl-10">
            <li className="flex items-center">
              <a style={{ transition: "all .15s ease" }} href="#contact">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
