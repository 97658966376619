import React from "react";

export default function Footer() {
  return (
    <footer className="relative bg-gray-800 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "81px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-800 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold text-neutral-50">
              Let's keep in touch!
            </h4>
            <h5 className="text-lg mt-0 mb-2 text-neutral-50">
              Find me on any of these platforms, I respond 1-2 business days.
            </h5>
            <div className="mt-6">
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <a
                    className=" px-3 py-4 lg:py-2 flex items-center text-xl uppercase font-bold"
                    href="https://www.facebook.com/birthandbilinka"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="flex fab fa-facebook-square text-white"></i>
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    className=" px-3 py-4 lg:py-2 flex items-center text-xl uppercase font-bold"
                    href="https://www.instagram.com/birthandbilinka"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="flex fab fa-instagram text-white"></i>
                  </a>
                </li>
                <li className="flex items-center">
                  <a
                    className=" px-3 py-4 lg:py-2 flex items-center text-xl uppercase font-bold"
                    href="https://birthandbilinka.etsy.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="flex fab fa-etsy text-white"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-neutral-50 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-neutral-50 hover:text-neutral-300 font-semibold block pb-2 text-sm"
                      href="#about"
                    >
                      About Me
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-neutral-50 hover:text-neutral-300 font-semibold block pb-2 text-sm"
                      href="https://birthandbilinka.etsy.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Etsy
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-neutral-50 hover:text-neutral-300 font-semibold block pb-2 text-sm"
                      href="https://birthandbilinka.etsy.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Products
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-neutral-50 text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-neutral-50 hover:text-neutral-300 font-semibold block pb-2 text-sm"
                      href="#contact"
                    >
                      Contact Me
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-neutral-50 font-semibold py-1">
              Copyright © {new Date().getFullYear()} Birth and Bilinka.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
