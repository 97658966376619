import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import Landing from "./views/Landing.js";

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <Router>
    <Routes>
      <Route path="/landing" element={<Landing />} />
      <Route path="/" element={<Navigate replace to="/landing" />} />
    </Routes>
  </Router>
);
